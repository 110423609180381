<template>
  <!-- <header class="nav-header"> -->
    <!-- </header> -->
  <div class="main-page">
      
    <router-view/>
    <div class="absolute-container">
      <nav class="nav-header">
        <router-link to="/">Accueil</router-link> |
        <router-link to="/prestations">Nos Prestations</router-link> |
        <router-link to="/alacarte">Logiciel à la carte</router-link>
      </nav>
    </div>
    <footer>
      <img src="./assets/logo net services transparent.png" alt="Logo NetServices">
      <div>
        <h3>TBD 1</h3>
        <h3>TBD 2</h3>
        <h3>TBD 3</h3>
      </div>
      <div>
        <h3>TBD 1</h3>
        <h3>TBD 2</h3>
        <h3>TBD 3</h3>
      </div>
      <div>
        <h3>TBD 1</h3>
        <h3>TBD 2</h3>
        <h3>TBD 3</h3>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'AppVue',
  data () {
    return {
      windowTop: 0
    }
  },
  methods: {
    onScroll () {
      this.windowTop = window.top.scrollY
    }
  },
  mounted () {
    window.addEventListener("scroll", this.onScroll)
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  }
}
</script>

<style>
:root {
  --light-blue: rgba(86, 131, 229, 0.865);;
  --dark-blue: rgb(45, 93, 123);
  --black: rgb(7, 2, 13);
  --light-green: rgb(87, 167, 115);
  --dark-green: rgba(21, 113, 69, 0.4);
}
* {
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.absolute-container {
  position: absolute;
  top: 10px;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}

nav {
  margin: 0 20px;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: sticky;
  top: 10px;
  /* float: right; */
  background-color: transparent;
  height: 100%;
  background-color: rgba(220, 220, 220, 0.753);
  border-radius: 16px;
}

nav a {
  font-weight: bold;
  color: var(--black);
}

.nav-header {
  position: sticky;
  top: 10px;
  width: 100%;
  height: 6vh;
  pointer-events: all;
}

body {
  margin: 0;
  padding: 0;
}

nav a.router-link-exact-active {
  color: #2a4aff;
}
.main-page {
  background-image: url('./assets/startupimg.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

footer {
  padding: 30px;
  height: 10vw;
  background-color: #2c3e50;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
footer div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
footer img {
  height: 100%;
  /* box-shadow: white 5px 5px 5px; */
  filter: drop-shadow(1px 1px 1px white);
}
</style>
