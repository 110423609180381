import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Accueil.vue')
  },
  {
    path: '/prestations',
    name: 'prestations',
    component: () => import('../views/Prestations.vue')
  },
  {
    path: '/alacarte',
    name: 'alacarte',
    component: () => import('../views/Carte.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

export default router
